import { handleAggregatorResponseWithHeaders } from '@wix/communities-blog-client-common';
import { createPromisifiedAction } from '../../../common/actions-promisifier/create-promisified-action';
import { handleTranslationsResponse } from '../../../common/store/translations/translations-actions';
import { getQueryLocale } from '../../../common/selectors/locale-selectors';
import { getIsICUBaseUrlEnabled } from '../../../common/selectors/icu-selectors';
import { getViewedUserSiteMemberId } from '../viewed-user/viewed-user-selectors';
import { getActiveTab } from '../active-tab/active-tab-selectors';
import { MEMBER_POSTS_PAGE_SIZE, POST_STATUS_BY_MEMBER_POSTS_PAGE_TAB } from '../../constants/member-posts-page';
import setPosts from '../../../common/actions/set-posts';
import { setPostCount } from '../../../common/store/post-count/set-posts-count';
import { getTotalResults } from '../../../common/services/pagination';
import { setIsLoading } from '../../../common/store/is-loading/is-loading-actions';

export const MEMBER_POSTS_ENTITY = 'member-posts';

export const fetchMemberPostsPageInitialData = fetchMemberPostsPageRenderModel;
export const fetchMemberPostsPageData = (page = 1) => fetchMemberPostsPageRenderModel({ page, fields: ['posts'] });
export const fetchMemberPostsPageDataPromisified = createPromisifiedAction(
  fetchMemberPostsPageData,
  () => null,
  (response) => response.status,
);

function fetchMemberPostsPageRenderModel({ page, fields } = {}) {
  return async (dispatch, getState, { aggregatorRequest, appParams }) => {
    const state = getState();
    const language = getQueryLocale(state);
    const siteMemberId = getViewedUserSiteMemberId(state);
    const useICU = getIsICUBaseUrlEnabled(appParams);
    const activeTab = getActiveTab(state);
    const status = POST_STATUS_BY_MEMBER_POSTS_PAGE_TAB[activeTab];

    dispatch(setIsLoading(MEMBER_POSTS_ENTITY, undefined, true));

    try {
      const { translations, posts } = await aggregatorRequest(
        `/v1/member-posts-page/render-model?${[
          `siteMemberId=${siteMemberId}`,
          `status=${status}`,
          `postLimit=${MEMBER_POSTS_PAGE_SIZE}`,
          language && `language=${language}`,
          page && `page=${page}`,
          useICU && 'icu=true',
          fields && `fields=${fields.join(',')}`,
        ]
          .filter(Boolean)
          .join('&')}`,
        { throwOnInvalidJson: true },
      );

      if (translations) {
        await dispatch(handleTranslationsResponse(translations));
      }

      const { body, headers } = await handleAggregatorResponseWithHeaders(posts)();
      await dispatch(setPosts(body));
      await dispatch(setPostCount(getTotalResults(headers)));
      dispatch(setIsLoading(MEMBER_POSTS_ENTITY, undefined, false));
    } catch (error) {
      dispatch(setIsLoading(MEMBER_POSTS_ENTITY, undefined, false));
      throw error;
    }
  };
}
